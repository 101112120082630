import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import vueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'
import PopupLogin from '@oarepo/vue-popup-login'
import CompositionApi from '@vue/composition-api'

Vue.config.productionTip = false;
Vue.use(CompositionApi)
Vue.use(VueAxios, axios);
Vue.use(VueCookie);
Vue.use(vueDebounce);
Vue.use(VueClipboard);
Vue.use(PopupLogin, {
  router,
  loginUrl: '/login/spotify'
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');