<template>
  <v-container fluid>
    <v-app-bar app>
      <v-app-bar-nav-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Log In</v-toolbar-title>
    </v-app-bar>

    <v-row justify="center">
      <v-col xs="12" sm="6" md="4">
        <v-card>
          <v-card-text>
            <p>PlaylistStats.com needs to connect to your Spotify account in order to proceed.</p>
          </v-card-text>
          
          <v-card-actions class="justify-center pb-5">
            <v-btn v-on:click="spotifyLogin" rounded color="green" class="pr-4"><v-icon class="mr-2">mdi-spotify</v-icon> Connect to Spotify</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({ drawer: null }),
    methods: {
      spotifyLogin: function () {
        this.axios
          .get(process.env.VUE_APP_API_BASE_URL + "/login/spotify")
          .then(response => {
            window.location = response.data.authorize_url;
          });
      }
    }
  }
</script>