<template>
  <v-app>
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer> -->

    <v-app-bar app>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="popupFailedSnackbar" multi-line :timeout="1e6" color="negative">
      Opening the popup for logging in has failed.
      You can either enable popups for this site and
      try again, or we can take you to login page -
      but you will loose any changes you might have performed
      on this page.

      <template v-slot:action="{ attrs }">
        <v-btn
            color="positive"
            v-bind="attrs"
            @click="retry()"
        >
          Try again
        </v-btn>
        <!-- <v-btn
            color="negative"
            v-bind="attrs"
            @click="redirect()"
        >
          Go to login page
        </v-btn> -->
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import { REDIRECT_LOGIN } from '@oarepo/vue-popup-login'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export default defineComponent({
  name: 'App',
  setup () {
    return {
      resolveFailedPopup: null,
      popupFailedSnackbar: false
    }
  },
  created () {
    console.log("faa")
    let auth = this.$auth
    this.$auth.options.popupFailedHandler = () => this.popupFailed()
    this.$auth.options.loginRequiredHandler = () => this.noAccess()

    axios.interceptors.request.use(function (config) {
      let token = Vue.cookie.get('auth-token');
      if (token) {
        config.headers['X-Auth-Token'] = token;
      }
      return config;
    }, null, { synchronous: true });

    // TODO: when calling the auth, don't let the interceptor run
    createAuthRefreshInterceptor(axios, (failedRequest) => {
      console.log('innnercept')
      return new Promise((resolve) => {
        let statusChannel = new BroadcastChannel("auth-token")
        statusChannel.onmessage = function (ev) {
          console.log("EVENT")
          console.log(ev)
          if (ev.data.event_name == "logged-in") {
            Vue.cookie.set('auth-token', ev.data.token)
            failedRequest.response.config.headers['X-Auth-Token'] = ev.data.token;
            resolve()
          }
        }

        Vue.cookie.delete('auth-token')
        auth.login()
      })
    })
  },
  methods: {
    popupFailed() {
      return new Promise(resolve => {
        this.popupFailedSnackbar = true
        this.resolveFailedPopup = resolve
      })
    },
    retry() {
      if (this.resolveFailedPopup !== null) {
        this.popupFailedSnackbar = false
        this.resolveFailedPopup(this.$auth.login())
      }
    },
    redirect() {
      if (this.resolveFailedPopup !== null) {
        this.popupFailedSnackbar = false
        this.resolveFailedPopup(REDIRECT_LOGIN)
      }
    }
  }
})
</script>
