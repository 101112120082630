<template>
  <v-container fluid>
    <v-app-bar app>
      <v-app-bar-nav-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>My Playlists</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-dialog
        v-model="add_playlist_dialog.visible"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <v-container>
          <v-row justify="center">
            <v-col xs="12" sm="8">
              <v-card>
                <v-card-title class="headline">
                  Add Playlist
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Playlist Link"
                          v-model="add_playlist_dialog.playlist_link"
                          v-on:input="add_playlist_dialog.playlist = null"
                          :disabled="add_playlist_dialog.submitting"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col
                        xs="12"
                        sm="6"
                      >
                        <v-card
                          v-if="add_playlist_dialog.playlist"
                          class="mx-auto"
                        >
                          <v-img
                            v-if="add_playlist_dialog.playlist.images && add_playlist_dialog.playlist.images[0]"
                            :src="add_playlist_dialog.playlist.images[0].url"
                          >
                          </v-img>

                          <v-card-title>
                            {{ add_playlist_dialog.playlist.name }}
                          </v-card-title>

                          <v-card-subtitle>
                            {{ add_playlist_dialog.playlist.description }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    @click="add_playlist_dialog_submit"
                    :loading="add_playlist_dialog.submitting"
                    :disabled="add_playlist_dialog.submitting"
                    color="green"
                  >
                    Add
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="add_playlist_dialog_cancel"
                    color="red"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>
    </v-app-bar>

    <v-row v-if="loading">
      <v-col>
        <v-layout justify-center>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col>
        <v-subheader v-if="curateds.length > 0">Playlists I Own</v-subheader>
        <v-list v-if="curateds.length > 0" subheader two-line>
          <v-list-item v-for="curated in curateds" :key="curated.name" style="cursor: pointer;">
            <v-list-item-avatar>
              <v-img :src="curated.spotify_playlist.images[0].url" v-if="curated.spotify_playlist.images && curated.spotify_playlist.images[0]"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="curated.spotify_playlist.name"
                @click="showCurated(curated.id)"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <template>
                <v-snackbar v-model="snackbar.visible">
                  {{ snackbar.text }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="pink"
                      text
                      v-bind="attrs"
                      @click="snackbar.visible = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    v-clipboard:copy="curated.invite_link"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  >
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>Copy Fan Invite Link</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-subheader v-if="fanneds.length > 0">Playlists I Follow</v-subheader>
        <v-list v-if="fanneds.length > 0">
          <v-list-item v-for="fanned in fanneds" :key="fanned.name">
            <v-list-item-avatar>
              <v-img :src="fanned.images[0].url" v-if="fanned.images && fanned.images[0]"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="fanned.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
        snackbar: {
          text: "",
          visible: false
        },
        add_playlist_dialog: {
          visible: false,
          playlist_link: "",
          playlist: null,
          submitting: false,
        },
        loading: false,
        curateds: [],
        fanneds: []
    }),
    methods: {
      copySuccess: function() {
        this.snackbar.text = "Copied!";
        this.snackbar.visible = true;
      },
      copyError: function(err) {
        this.snackbar.text = err;
        this.snackbar.visible = true;
      },
      showCurated: function(id) {
        this.$router.push('/curator/playlist/' + id)
      },
      loadPlaylists: function() {
        this.loading = true
        this.axios
          .get(process.env.VUE_APP_API_BASE_URL + "/playlists")
          .then(response => {
            this.curateds = response.data.curated.map(curated => {
              curated.invite_link = window.location.protocol + "//" + window.location.host + "/invite/playlist?code=" + encodeURIComponent(curated.invite_code);
              return curated;
            });
            this.fanneds = response.data.fanned;
            this.loading = false
            console.log(response.data)
          }, error => {
            this.loading = false
            console.log(error);
          });
      },
      add_playlist_dialog_submit: function () {
        if (!this.add_playlist_dialog.playlist) {
          let item = this.add_playlist_dialog.playlist_link;
          if (item.length < "spotify:x:".length) {
            return;
          }

          this.add_playlist_dialog.submitting = true
          this.axios
            .get(process.env.VUE_APP_API_BASE_URL + "/proxy/spotify", {
              params: { item }
            })
            .then(response => {
              this.add_playlist_dialog.submitting = false
              console.log(response.data);
              this.add_playlist_dialog.playlist = response.data;
            }, error => {
              this.add_playlist_dialog.submitting = false
              console.log("ERROR")
              console.log(error)
            })
        } else {
          this.add_playlist_dialog.submitting = true
          this.axios
            .post(process.env.VUE_APP_API_BASE_URL + "/curator/playlists", {
              playlist_href: this.add_playlist_dialog.playlist.href
            })
            .then(response => {
              this.add_playlist_dialog.submitting = false
              if (200 == response.status) {
                this.snackbar.text = "Playlist Added!"
                this.snackbar.visible = true
                this.add_playlist_dialog.playlist_link = ''
                this.add_playlist_dialog.playlist = null
                this.add_playlist_dialog.visible = false
                this.loadPlaylists()
              }
            }, error => {
              console.log("ERROR")
              console.log(error)
              this.add_playlist_dialog.submitting = false
            })
        }
      },
      add_playlist_dialog_cancel: function() {
        this.add_playlist_dialog.playlist_link = '';
        this.add_playlist_dialog.playlist = null;
        this.add_playlist_dialog.visible = false
      }
    },
    mounted: function () {
      this.loadPlaylists()
    }
  }
</script>
