import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SpotifyLogin from '../views/SpotifyLogin.vue'
import SpotifyLoginCallback from '../views/SpotifyLoginCallback.vue'
import MyPlaylists from '../views/MyPlaylists.vue'
import PlaylistInvite from '../views/PlaylistInvite.vue'
import ShowCuratorPlaylist from '../views/ShowCuratorPlaylist.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/spotify',
    name: 'SpotifyLogin',
    component: SpotifyLogin
  },
  {
    path: '/login/spotify/callback',
    name: 'SpotifyLoginCallback',
    component: SpotifyLoginCallback
  },
  {
    path: '/invite/playlist',
    name: 'PlaylistInvite',
    component: PlaylistInvite
  },
  {
    path: '/playlists',
    name: 'MyPlaylists',
    component: MyPlaylists
  },
  {
    path: '/curator/playlist/:id',
    name: 'ShowCuratorPlaylist',
    component: ShowCuratorPlaylist
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
