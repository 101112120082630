<template>
  <v-container fluid>
    <v-app-bar app>
      <v-toolbar-title>Invitation</v-toolbar-title>
    </v-app-bar>

    <v-row justify="center">
      <v-col xs="12" sm="6" md="4">
        <v-card>
          <v-card-text>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-if="!loading && !accepted">
              <h1>{{ playlist_name }}</h1>
              <p>You have been invited to become a fan.</p>
            </div>
            <div v-if="accepted">
              <h1>Success!</h1>
              <p>You have successfully joined the playlist.</p>
            </div>
          </v-card-text>
          
          <v-card-actions class="justify-center pb-5" v-if="!loading && !accepted">
            <v-btn
              v-on:click="acceptInvite"
              :loading="accepting"
              :disabled="accepting"
              rounded
              color="green"
            >
              Accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      loading: true,
      accepting: false,
      accepted: false,
      playlist_name: "",
    }),
    mounted: function () {
      this.axios
        .get(process.env.VUE_APP_API_BASE_URL + "/playlists/invites/" + this.$route.query.code)
        .then(response => {
          this.playlist_name = response.data.playlist_name
          this.loading = false
        });
    },
    methods: {
      acceptInvite: function () {
        this.accepting = true
        this.axios
          .post(process.env.VUE_APP_API_BASE_URL + "/playlists/invites/" + this.$route.query.code)
          .then(response => {
            this.accepting = false
            this.accepted = true
            console.log("success");
            console.log(response);
          });
      }
    }
  }
</script>