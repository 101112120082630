<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Please wait</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <p>Connecting to Spotify...</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({ drawer: null }),
    mounted: function () {
      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "/login/spotify", {
          code: this.$route.query.code,
          state: this.$route.query.state
        })
        .then(response => {
          if (200 == response.status) {
            let expires = new Date(response.data.expiry);
            this.$cookie.set('auth-token', response.data.auth_token, { expires })
            let statusChannel = new BroadcastChannel("auth-token")
            statusChannel.postMessage({ event_name: 'logged-in', token: response.data.auth_token })
            window.close()
          }
        },
        error => {
          if (401 == error.response.status) {
            console.log("TODO: Something wrong with token.");
            this.$router.replace('/login/spotify');
          }
        });
    }
  }
</script>
