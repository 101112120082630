<template>
  <v-container fluid>
    <v-app-bar app>
      <v-toolbar-title v-if="!loaded">Playlist</v-toolbar-title>
      <v-toolbar-title v-if="loaded">{{ playlist_name }}</v-toolbar-title>
    </v-app-bar>

    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-sheet
            class="v-sheet--offset mx-auto"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :labels="fan_play_graph_labels"
              :value="fan_play_graph_values"
              auto-draw="true"
              label-size="4"
              stroke-linecap="round"
              smooth
              line-width="1"
              padding="10"
              height="45"
            >
              <template v-slot:label="item">
                {{ item.value }}
              </template>
            </v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              Playlist Completions
            </div>
          </v-card-text>
          <v-card-title>
            <v-text-field
              v-model="fan_play_summary.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="fan_play_summary.headers"
            :items="fan_play_summary.fan_plays"
            :search="fan_play_summary.search"
          >
            <template v-for="header in fan_play_summary.headers" v-slot:[`item.${header.value}`]="{ item }">
              <div :class="fanPlayCellStyle(item[header.value])" :key="header.value">
                {{ item[header.value] }}
              </div>
            </template>
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      loaded: true,
      playlist_name: "Rock and Metal Mania",
      // fan_play_graph_labels: ["2020-03-28", "2020-03-29", "2020-03-30", "2020-03-31", "2020-04-01", "2020-04-02", "2020-04-03"],
      // fan_play_graph_values: [4, 2, 3, 3, 2, 2, 2],
      fan_play_graph_labels: [],
      fan_play_graph_values: [],
      fan_play_summary: {
        search: "",
        headers: [
          { text: "Name", value: "name", align: "start" },
          { text: "t-6", value: "t-6", filterable: false },
          { text: "t-5", value: "t-5", filterable: false },
          { text: "t-4", value: "t-4", filterable: false },
          { text: "t-3", value: "t-3", filterable: false },
          { text: "t-2", value: "t-2", filterable: false },
          { text: "t-1", value: "t-1", filterable: false },
          { text: "t", value: "t", filterable: false }
          // { text: "2020-03-28", value: "2020-03-28", filterable: false },
          // { text: "2020-03-29", value: "2020-03-29", filterable: false },
          // { text: "2020-03-30", value: "2020-03-30", filterable: false },
          // { text: "2020-03-31", value: "2020-03-31", filterable: false },
          // { text: "2020-04-01", value: "2020-04-01", filterable: false },
          // { text: "2020-04-02", value: "2020-04-02", filterable: false },
          // { text: "2020-04-03", value: "2020-04-03", filterable: false }
        ],
        fan_plays: []
        //   {
        //     name: 'Matt Yu',
        //     '2020-03-28': 2,
        //     '2020-03-29': 0,
        //     '2020-03-30': 1,
        //     '2020-03-31': 1,
        //     '2020-04-01': 1,
        //     '2020-04-02': 1,
        //     '2020-04-03': 1
        //   },
        //   {
        //     name: 'Feminist Baby',
        //     '2020-03-28': 1,
        //     '2020-03-29': 1,
        //     '2020-03-30': 1,
        //     '2020-03-31': 1,
        //     '2020-04-01': 1,
        //     '2020-04-02': 1,
        //     '2020-04-03': 1
        //   },
        //   {
        //     name: 'John Smith',
        //     '2020-03-28': 1,
        //     '2020-03-29': 1,
        //     '2020-03-30': 1,
        //     '2020-03-31': 1,
        //     '2020-04-01': 0,
        //     '2020-04-02': 0,
        //     '2020-04-03': 0
        //   }
        // ]
      }
    }),
    mounted () {
      this.axios
        .get(process.env.VUE_APP_API_BASE_URL + "/curator/playlist/1")
        .then(response => {
          console.log(response)
          // this.fan_play_summary.headers = 
          // { text: "Name", value: "name", align: "start" },
          // { text: "2020-03-28", value: "2020-03-28", filterable: false },
          // { text: "2020-03-29", value: "2020-03-29", filterable: false },
          // { text: "2020-03-30", value: "2020-03-30", filterable: false },
          // { text: "2020-03-31", value: "2020-03-31", filterable: false },
          // { text: "2020-04-01", value: "2020-04-01", filterable: false },
          // { text: "2020-04-02", value: "2020-04-02", filterable: false },
          // { text: "2020-04-03", value: "2020-04-03", filterable: false }

          let fan_names = Object.keys(response.data.fan_plays)
          let first_fan_name = fan_names[0]
          let dates = Object.keys(response.data.fan_plays[first_fan_name]).sort()
          var headers = [{ text: "Name", value: "name", align: "start" }]
          dates.forEach(d => {
            headers.push({ text: d, value: d, filterable: false })
          })
          this.fan_play_summary.headers = headers
          this.fan_play_summary.fan_plays = fan_names.map(name => {
            return { name: name, ...response.data.fan_plays[name] };
          })

          this.fan_play_graph_labels = dates
          this.fan_play_graph_values = dates.map(d => {
            var total = 0
            fan_names.forEach(n => {
              total += response.data.fan_plays[n][d]
            })
            return total
          })
          console.log(this.fan_play_summary.headers)
          console.log(this.fan_play_summary.fan_plays)
        })
    },
    methods: {
      fanPlayCellStyle: function (contents) {
        if (typeof contents === "number" && contents == 0) {
          return 'red--text font-weight-bold'
        }

        return ''
      }
    }
  }
</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>
